@import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
@import "./react-big-calendar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

#elmish-app {
    height: 100vh;
}

@layer base {
    @font-face {
        font-family: "Inter";
        src: url('./assets/fonts/InterVariableFont.ttf') format('truetype');
    }

    @font-face {
        font-family: "Ubuntu";
        src: url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Ubuntu";
        src: url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Ubuntu";
        src: url('./assets/fonts/Ubuntu-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Ubuntu";
        src: url('./assets/fonts/Ubuntu-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
}